interface Config {
  BUILD: string;
  FB_APP_ID: string;
  WEB_GTM_ID: string | undefined;
  ANDROID_GTM_ID: string | undefined;
  IOS_GTM_ID: string | undefined;
  GOOGLE_CLIENT_ID: string;
  SENTRY_DSN_IOS: string | undefined;
  SENTRY_DSN_ANDROID: string | undefined;
  SITE_URL: string;
  GRAPHQL_ENDPOINT: string;
  RESTAPI_ENDPOINT: string;
  DEEPLINK_URLS?: string[];
  DYNAMICLINK_URLS?: string[];
  STORE_VIEW_CODE: {
    EN_US: string;
    ZH_HANT_HK: string;
  };
  DEFAULT_CATEGORY_ID: number;
  THE_CLUB_UAT_CONFIG: {
    SITE_URL?: string;
    AUTH_SERVER_URL: string;
    REALM: string;
    CLIENT_ID: string;
    BACK_URI: string;
    KC_IDP_HINT?: string;
  };
  THE_CLUB_LOGIN_URL: string;
  LIVE_CHAT_URL?: string;
  FOOTER_CMS_LINKS_REGEX_SHOULD_OPEN_IN_APP_BROWSER?: string[];
  IMAGE_WIDTH: number;
  IMAGE_HEIGHT: number;
  BITLY_ACCESS_TOKEN: string;
  BITLY_DOMAINS: string[];

  OPPPAYMENT_REDIRECT_SUCCESS_RESULT_URLS?: string[];
  OPPPAYMENT_REDIRECT_SUCCESS_URLS?: string[];
  OPPPAYMENT_REDIRECT_FAILED_URLS?: string[];
  OPPPAYMENT_REDIRECT_CANCELLED_URLS?: string[];

  ALIPAY_REDIRECT_END_URLS?: string[];

  MAX_RECENTLY_SEARCHES_TO_STORE: number;
  SEARCH_PRODUCT_SUGGESTION_LIMIT: number;
  WEB_APP_VERSION: string;
  WEB_BUILD_NUMBER: string;

  SHOW_CLUBPOINT_JOURNEY: boolean;

  MAX_HORIZONTAL_LIST_ITEMS?: number;

  SHOPPING_CART_ICON_ANIMATION_INTERVAL_IN_MS: number;

  HIDE_OAUTH_FOR_IOS: boolean;

  ERROR_RERENDER_LIMIT?: number;

  ADS?: {
    INTERSTITIAL?: string;
    BANNER?: {
      AD_UNIT_ID: string;
      SIZE: "normal" | "large";
    };
  };

  SHOW_ALERT_ON_SENTRY_ERROR: boolean;
  BEACON_REGIONS: BeaconRegion[];

  USE_PUSH: boolean;
  SHOW_ENVELOPE_ICON: boolean;
  OPNS_ANDROID_PLATFORM?: "GCM" | "FCM";

  TIMEZONE: string;

  VIRTUAL_WAITING_ROOM?: {
    ENDPOINT: string;
  };

  SIGN_UP_TERMS_AND_CONDITIONS_LINK?: string;
  SIGN_UP_PRIVACY_POLICY_LINK?: string;

  CLUB_PROTECT_ORDER_DETAIL_MODAL_BUTTON_1_LINK?: string;
  CLUB_PROTECT_ORDER_DETAIL_MODAL_BUTTON_2_LINK?: string;
  CLUB_PROTECT_ORDER_DETAIL_MODAL_BUTTON_3_LINK?: string;
  CLUB_PROTECT_FAQ_LINK?: string;
  NON_DELIVERY_AREA_LINK?: string;

  PRODUCT_DETAILS_RELATED_PRODUCTS_MAX_COUNT?: number;
  PRODUCT_DETAILS_RELATED_PRODUCTS_SHUFFLE_WHEN_COUNT_GREATER_THEN?: number;

  APPLE_PAY?: {
    MERCHANT_ID?: string;
    COUNTRY_CODE?: string;
    SUPPORTED_NETWORKS?: string[];
    MERCHANT_CAPABILITIES?: string[];
    TOTAL_LINE_ITEM_LABEL?: string;
  };

  LUCKY_DRAW?:
    | {
        PRODUCT_SKUS?: string[] | unknown;
        OPT_OUT_MSGS_ZH_HANT?: string[] | unknown;
        OPT_OUT_MSGS_EN?: string[] | unknown;
        SHOPPING_CART_PRODUCT_URL?: string | unknown;
      }[]
    | unknown;

  SMART_PROTECTION_DETAILS_URL?: string;

  ENABLE_ALIPAYHK?: boolean;

  ENABLE_SUBSCRIPTION?: boolean;
  ENABLE_SALE_AND_NEW_PRODUCT_TAG?: boolean;
  ENABLE_REDEMPTION_TAB?: boolean;
  ENABLE_HASHED_QUERY?: boolean;
  ENABLE_CLUB_PROTECTION_BY_TIER_AFTER?: Date | unknown;
  ENABLE_BRAND?: boolean;
  ENABLE_BUNDLE_SALE?: boolean;
  ENABLE_BUNDLE_SALE_BADGE_PRODUCT_LIST?: boolean;
  BUNDLE_SALE_LIMIT_IN_SHOPPING_CART?: number;
  ENABLE_SHOPPING_CART_NEW_LAYOUT_2912?: boolean;
  SHOPPING_CART_FREE_SHIPPING_GRAND_TOTAL?: number;
  ENABLE_CACHE_HASHED_QUERY_RESULT?: boolean;
  ENABLE_BINGO_LIST?: boolean;
  ENABLE_THE_CLUB_SSO?: boolean;

  // After enabled `THE_CLUB_SSO_ONE_TIME_TOKEN_URL`,
  // all external links will formed after one time token
  // is retrieved from `THE_CLUB_SSO_ONE_TIME_TOKEN_URL`
  // for The Club member sessions.
  // - openUrlWithBrowser
  // - IFramePage
  THE_CLUB_SSO_ONE_TIME_TOKEN_URL?: string;
  THE_CLUB_SSO_ONE_TIME_TOKEN_APPLY_HREFS?: string[];

  THE_CLUB_CLUB_POINT_HISTORY_URL_EN?: string;
  THE_CLUB_CLUB_POINT_HISTORY_URL_ZH?: string;

  ENABLE_THE_CLUB_SSO_MVP1?: {
    // After enabled `THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE` and
    // `ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_MEMBER_PROFILE_URL`
    // the edit profile action will launch in app browser with
    // `ENABLE_THE_CLUB_SSO_MVP1.THE_CLUB_MEMBER_PROFILE_URL`
    // instead of using in app edit profile page for The Club members.
    // - Change password will be removed from account page in logged in state
    // - Account information page will be shown instead of edit profile page
    //   for account details
    THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE?: boolean;
    THE_CLUB_MEMBER_PROFILE_URL_EN?: string;
    THE_CLUB_MEMBER_PROFILE_URL_ZH?: string;
    THE_CLUB_SSO_CONFIRMATION_DIALOGS?: boolean;
  };
  ENABLE_PRE_ORDER_AND_EDD?: boolean;
  ENABLE_SET_EMAIL_ADDRESS_ON_CART?: boolean;
  ENABLE_SET_NAME_ON_RODUCT_REVIEW?: boolean;

  INFO_ENABLE_PERFORMANCE_LOGGING?: boolean;
  INFO_ENABLE_CAPTURE_WEB_VIEW_WEB_CONTENT_PROCESS_DID_TERMINATE?: boolean;

  DEVELOPMENT_ENABLE_AGGREGATED_CART_MUTATIONS?: boolean;
  ENABLE_BINGO_LIST_TRACKING?: boolean;
}
const Config: Config = {
  ...window.appConfig,
  MAX_RECENTLY_SEARCHES_TO_STORE: 10,
  SEARCH_PRODUCT_SUGGESTION_LIMIT: 3,
  SHOW_CLUBPOINT_JOURNEY: false,
  SHOPPING_CART_ICON_ANIMATION_INTERVAL_IN_MS: 8000,
};

export default Config;
