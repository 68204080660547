import React, { useCallback, useContext, useEffect } from "react";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";

import { apply } from "../../utils/type";
import { addPerformanceRecord } from "../../utils/PerformanceRecordStore";
import { InitializeHomePageSession } from "../../utils/PerformanceRecordStore/sessions";

import {
  PresentationContextProvider,
  OurNavManager,
  OurTabs,
  OurRouterOutlet,
  OurNavContext,
  PresentationRoutes as OurPresentationRoutes,
  OurRouterOutletContext,
  ParentNavContextProvider,
} from "../../our-navigation";

import {
  getPathForHomePage,
  getRouteDefinitionForSingleCategoryPage,
  getRouteDefinitionForSubCategoryListPage,
  getRouteDefinitionForProductDetailPage,
  getRouteDefinitionForDisplayLanguagePage,
  getRouteDefinitionForSelectInterestCategoryPage,
  getRouteDefinitionForMerchantDirectories,
  getRouteDefinitionForEditProfile,
  getRouteDefinitionForSingleMerchant,
  getRouteDefinitionForMerchantDetails,
  RootTab,
  AllTabs,
  getRouteDefinitionForTab,
  getPathForShoppingCart,
  getRouteDefinitionForCheckoutFillingInfo,
  getRouteDefinitionForCheckoutSelectO2oStore,
  getRouteDefinitionForCheckoutConfirmation,
  getRouteDefinitionForCheckoutOrdered,
  getRouteDefinitionForSearchSuggestion,
  getRouteDefinitionForSearch,
  getRouteDefinitionForMyOrders,
  getRouteDefinitionForOrderDetail,
  getRouteDefinitionForOrderDetailsQRCodesRedemptionPage,
  getRouteDefinitionForMyDelivery,
  getRouteDefinitionForArticleDetail,
  getRouteDefinitionForFilteredArticleListPage,
  getRouteDefinitionForSignupClubPointPage,
  getRouteDefinitionForAddressForm,
  getRouteDefinitionForCMSLanding,
  getRouteDefinitionForForgotPassword,
  getRouteDefinitionForNotificationSettings,
  getRouteDefinitionForChangeEmail,
  getRouteDefinitionForMPOSPayPayment,
  getRouteDefinitionForFooterCMSLinkGroup,
  getRouteDefinitionForFooterCMSLink,
  getRouteDefinitionForChangePassword,
  getRouteDefinitionForPushNotificationMessages,
  getRouteDefinitionForCustomerProductReview,
  getRouteDefinitionForProductReviews,
  getRouteDefinitionForMyCards,
  getRouteDefinitionForMySubscriptions,
  getRouteDefinitionForSubscriptionDetails,
  getRouteDefinitionForClubProtectClaimApplication,
  getRouteDefinitionForBrandIndexPage,
  getRouteDefinitionForBrandsSearchPage,
  getRouteDefinitionForSingleBrandPage,
  getRouteDefinitionForFilterModal,
  getRouteDefinitionForPerformanceRecords,
  getRouteDefinitionForBingoList,
  getRouteDefinitionForCampaignDetailsPage,
  getRouteDefinitionForCampaignProductDetailsPage,
  getRouteDefinitionForIFramePage,
  getRouteDefinitionForAccountInformation,
} from "../../navigation/routes";

import { withProviders } from "../Provider";
import { LoginSignupModalProvider } from "../LoginSignupModalProvider";
import { AddToCartModalProvider } from "../AddToCartModalProvider";
import AddProductReviewModalProvider from "../AddProductReviewModalProvider";

import AccountPage from "../AccountPage";
import AllCategoriesPage from "../AllCategoriesPage";
import CategoryPage from "../CategoryPage";
import DisplayLanguagePage from "../AccountPage/DisplayLanguagePage";
import HomePage from "../HomePage";
import LikesPage from "../LikesPage";
import SingleCategoryPage from "../SingleCategoryPage";
import SubCategoryListPage from "../SubCategoryListPage";
import SelectInterestCategoryPage from "../SelectInterestCategoryPage";
import MerchantDirectoriesPage from "../MerchantDirectoriesPage";
import SingleMerchantPage from "../SingleMerchantPage";
import MerchantDetailsPage from "../MerchantDetailsPage";
import FillingInfo from "../Checkout/FillingInfo";
import CheckoutSelectO2oStorePage from "../CheckoutSelectO2oStorePage";
import CheckoutConfirmationPage from "../CheckoutConfirmationPage";
import CheckoutOrderedPage from "../CheckoutOrderedPage";
import RedemptionPage from "../RedemptionPage";

import TabBar, { TarBarHeightProvider } from "./TabBar";
import OnAppLoad from "../OnAppLoad";
import BackAndroidHandler from "../BackAndroidHandler";
import FilterModal from "../FilterModal/lazy";
import { EditProfilePage } from "../EditProfilePage";
import AccountInformationPage from "../AccountInformationPage";
import ShoppingCartModal from "../ShoppingCartModal/lazy";
import SearchSuggestionPage from "../SearchPages/SearchSuggestionPage";
import SearchPage from "../SearchPages/SearchPage";
import DeepLinkHandler from "../DeepLinkHandler";
import MyOrdersPage from "../MyOrdersPage";
import OrderDetailPage from "../OrderDetailPage";
import OrderDetailsQRCodesRedemptionPage from "../OrderDetailsQRCodesRedemptionPage";
import MyDeliveryInfoPage from "../MyDeliveryInfoPage";
import RootArticleListPage from "../ArticlePages/RootArticleListPage";
import ArticleDetailPage from "../ArticlePages/ArticleDetailPage";
import FilteredArticleListPage from "../ArticlePages/FilteredArticleListPage";
import SignupClubPointPage from "../SignupClubPointPage";
import AddressFormModal from "../AddressFormModal/lazy";
import CMSLandingPage from "../CMSLandingPage";
import ForgotPasswordPage from "../ForgotPasswordPage";
import NotificationSettingsPage from "../NotifictionSettingsPage";
import ChangeEmailPage from "../ChangeEmailPage";
import MPOSPayPaymentPage from "../MPOSPayPaymentPage";
import FooterCMSLinkGroupPage from "../FooterCMSLinkGroupPage";
import FooterCMSLinkPage from "../IFramePage/FooterCMSLinkPage";
import ChangePasswordPage from "../ChangePasswordPage";
import CustomerProductReviewPage from "../CustomerProductReviewPage";
import PushNotificationMessagePage from "../PushNotificationMessagesPage/lazy";
import ProductReviewsPage from "../ProductReviewsPage/lazy";
import MyCardsPage from "../MyCardsPage";
import MySubscriptionsPage from "../MySubscriptionsPage";
import SubscriptionDetailsPage from "../SubscriptionDetailsPage";
import ClubProtectClaimApplicationModal from "../ClubProtectClaimApplicationModal/lazy";
import BrandIndexPage from "../BrandIndexPage";
import BrandsSearchPage from "../BrandIndexPage/Search";
import SingleBrandPage from "../SingleBrandPage";
import PerformanceRecordsPage from "../PerformanceRecordsPage";
import BingoListPage from "../BingoListPage";
import CampaignDetailsPage from "../CampaignDetailsPage";
import CampaignProductDetailPage from "../ProductDetailPage/CampaignProductDetailPage";
import NormalProductDetailPage from "../ProductDetailPage/NormalProductDetailPage";
import { IFramePage } from "../IFramePage";

const DefaultPopOnAndroidBack: React.FC = () => {
  const { goBack } = useContext(OurNavContext);
  const onBackPress = useCallback(() => {
    goBack();
    return true;
  }, [goBack]);
  return <BackAndroidHandler onBackPress={onBackPress} />;
};

const PresentationRoutes: React.FC = () => {
  return (
    <OurPresentationRoutes>
      <Route
        path={getRouteDefinitionForFilterModal()}
        component={FilterModal}
        exact={true}
      />
      <Route
        path={getPathForShoppingCart()}
        component={ShoppingCartModal}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutFillingInfo()}
        component={FillingInfo}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutSelectO2oStore()}
        component={CheckoutSelectO2oStorePage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutConfirmation()}
        component={CheckoutConfirmationPage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForCheckoutOrdered()}
        component={CheckoutOrderedPage}
        preventGoBack={true}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForAddressForm()}
        component={AddressFormModal}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForForgotPassword()}
        component={ForgotPasswordPage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForMPOSPayPayment()}
        component={MPOSPayPaymentPage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForPushNotificationMessages()}
        component={PushNotificationMessagePage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForProductReviews()}
        component={ProductReviewsPage}
        exact={true}
      />
      <Route
        path={getRouteDefinitionForClubProtectClaimApplication()}
        component={ClubProtectClaimApplicationModal}
        exact={true}
      />
    </OurPresentationRoutes>
  );
};

function renderRoutesForTab(tab: RootTab) {
  const tabRouteDefinition = getRouteDefinitionForTab(tab);
  let TabPageComponent: React.ComponentType<any>;
  switch (tab) {
    case RootTab.allCategories: {
      TabPageComponent = AllCategoriesPage;
      break;
    }
    case RootTab.category: {
      TabPageComponent = CategoryPage;
      break;
    }
    case RootTab.articles: {
      TabPageComponent = RootArticleListPage;
      break;
    }
    case RootTab.home: {
      TabPageComponent = HomePage;
      break;
    }
    case RootTab.likes: {
      TabPageComponent = LikesPage;
      break;
    }
    case RootTab.account: {
      TabPageComponent = AccountPage;
      break;
    }
    case RootTab.redemption: {
      TabPageComponent = RedemptionPage;
      break;
    }
    default:
      throw new Error("Unreachable");
  }
  let key = 0;
  return [
    <Route
      key={tab}
      path={tabRouteDefinition}
      component={TabPageComponent}
      exact={true}
      isRootTab={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForCMSLanding(tab)}
      component={CMSLandingPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMerchantDirectories(tab)}
      component={MerchantDirectoriesPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSingleMerchant(tab)}
      component={SingleMerchantPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMerchantDetails(tab)}
      component={MerchantDetailsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSingleCategoryPage(tab)}
      component={SingleCategoryPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSubCategoryListPage(tab)}
      component={SubCategoryListPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForDisplayLanguagePage(tab)}
      component={DisplayLanguagePage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForProductDetailPage(tab)}
      component={NormalProductDetailPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSelectInterestCategoryPage(tab)}
      component={SelectInterestCategoryPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForEditProfile(tab)}
      component={EditProfilePage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForAccountInformation(tab)}
      component={AccountInformationPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForNotificationSettings(tab)}
      component={NotificationSettingsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSearchSuggestion(tab)}
      component={SearchSuggestionPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSearch(tab)}
      component={SearchPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMyOrders(tab)}
      component={MyOrdersPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForOrderDetail(tab)}
      component={OrderDetailPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForOrderDetailsQRCodesRedemptionPage(tab)}
      component={OrderDetailsQRCodesRedemptionPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMyDelivery(tab)}
      component={MyDeliveryInfoPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForArticleDetail(tab)}
      component={ArticleDetailPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForFilteredArticleListPage(tab)}
      component={FilteredArticleListPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSignupClubPointPage(tab)}
      component={SignupClubPointPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForChangeEmail(tab)}
      component={ChangeEmailPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForFooterCMSLinkGroup(tab)}
      component={FooterCMSLinkGroupPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForFooterCMSLink(tab)}
      component={FooterCMSLinkPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForChangePassword(tab)}
      component={ChangePasswordPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForCustomerProductReview(tab)}
      component={CustomerProductReviewPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMyCards(tab)}
      component={MyCardsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForMySubscriptions(tab)}
      component={MySubscriptionsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSubscriptionDetails(tab)}
      component={SubscriptionDetailsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForBrandIndexPage(tab)}
      component={BrandIndexPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForBrandsSearchPage(tab)}
      component={BrandsSearchPage}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForSingleBrandPage(tab)}
      component={SingleBrandPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForPerformanceRecords(tab)}
      component={PerformanceRecordsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForBingoList(tab)}
      component={BingoListPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForCampaignDetailsPage(tab)}
      component={CampaignDetailsPage}
      exact={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForCampaignProductDetailsPage(tab)}
      component={CampaignProductDetailPage}
      exact={true}
      hideTabBar={true}
    />,
    <Route
      key={`tab-${key++}`}
      path={getRouteDefinitionForIFramePage(tab)}
      component={IFramePage}
      exact={true}
    />,
  ];
}

function renderRoutes() {
  return [
    ...AllTabs.map(renderRoutesForTab),
    <Route
      key="redirect"
      path="/"
      // eslint-disable-next-line react/jsx-no-bind
      render={() => <Redirect to={getPathForHomePage()} />}
      exact={true}
    />,
  ];
}

const AppNavigator: React.FC = () => {
  useEffect(() => {
    addPerformanceRecord(InitializeHomePageSession(), "App Navigator Mounted");
  }, []);

  return (
    <BrowserRouter>
      <PresentationContextProvider>
        <OurNavManager>
          <AppViews />
        </OurNavManager>
      </PresentationContextProvider>
    </BrowserRouter>
  );
};

export default React.memo(AppNavigator);

const AppViewsImpl: React.FC = () => {
  const { routerOutletKeys } = useContext(OurRouterOutletContext);

  const onHideTabBar = useCallback(() => {
    apply(document.querySelector("ion-tab-bar"), ionTabBar => {
      ionTabBar.classList.add("always-hide");
    });
  }, []);

  const onShowTabBar = useCallback(() => {
    apply(document.querySelector("ion-tab-bar"), ionTabBar => {
      ionTabBar.classList.remove("always-hide");
    });
  }, []);
  return (
    <>
      <ParentNavContextProvider>
        <PresentationRoutes />
      </ParentNavContextProvider>
      <OnAppLoad />
      <DeepLinkHandler />
      <DefaultPopOnAndroidBack />
      <TarBarHeightProvider>
        <OurTabs>
          {/*
              TODO (Steven-Chan):
              render multiple OurRouterOutlet instead of directly setting the
              key to prevent screen flicker
            */}
          <div>
            {routerOutletKeys.map(k => (
              <OurRouterOutlet
                key={k}
                onHideTabBar={onHideTabBar}
                onShowTabBar={onShowTabBar}
              >
                <Switch>{renderRoutes()}</Switch>
              </OurRouterOutlet>
            ))}
          </div>
          <TabBar />
        </OurTabs>
      </TarBarHeightProvider>
    </>
  );
};

const AppViews = withProviders(
  AppViewsImpl,
  LoginSignupModalProvider,
  AddToCartModalProvider,
  AddProductReviewModalProvider
);
