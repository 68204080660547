import React, {
  useContext,
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
  CSSProperties,
} from "react";
import classnames from "classnames";
import {
  // eslint-disable-next-line
  IonHeader,
  IonLifeCycleContextInterface,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";

import { BackAndroidExitApp } from "../BackAndroidHandler";
import CLContent from "../CLContent";
import { AccountLinkTheClubSection } from "../AccountTheClubSection";
import { LocalizedText } from "../../i18n/Localization";
import SocialSignupTermsAndConditionsModal from "../SocialSignupTermsAndConditionsModal";
import { TabBarSpacePlaceholder } from "../navigation/TabBar";

import NoInternetConnectionView from "../NoInternetConnectionView";
import { NetworkStatusContext } from "../NetworkStatusProvider";
import { LoadingModalContext } from "../LoadingModalProvider";
import TheClubSSOConfirmationFlow, {
  TheClubSSOCOnfirmationStatusResult,
} from "../TheClubSSOConfirmationModals/TheClubSSOConfirmationFlow";

import { LoginSignupModalContext } from "../LoginSignupModalProvider";
import { LocalizedAlertContext } from "../LocalizedAlertProvider";

import { Setting, getSettings, SettingDisplayInfo } from "./model";
import {
  Customer,
  getCustomerFullName,
  getCustomerProfilePicUrl,
  isCustomerLinkedToTheClub,
  getCustomerClubPoints,
} from "../../models/Customer";

import {
  useCustomer,
  useLogout,
  useLoginWithOAuthRequest,
  useRefreshCustomer,
  useGetMyCustomer,
} from "../../repository/AuthRepository";

import {
  SSOResponse,
  useGetTheClubSSOConfirmationStatus,
  useLoginWithTheClub,
} from "../../useCase/AuthUseCase";

import { DeviceInfo, getInfo } from "../../CLPlugins/DeviceInfo";

import {
  useCheckIsScreenActive,
  getPathForDisplayLanguagePage,
  getPathForHomePage,
  getPathForEditProfile,
  getPathForMyOrders,
  getPathForMySubscriptions,
  RootTab,
  getPathForChangePassword,
  getPathForMyDelivery,
  getPathForMyCards,
  getPathForNotificationSettings,
  getPathForFooterCMSLinkGroup,
  getPathForCustomerProductReview,
  getPathForRedemptionTab,
  getPathForPerformanceRecords,
  getPathForBingoList,
  getPathForAccountInformation,
} from "../../navigation/routes";
import { OurNavContext } from "../../our-navigation";

import useScrollToHideTabBar from "../../utils/scrollToHideTabBar";
import {
  pageView,
  actionEvent,
  bingolistProfileClickEvent,
  bingolistProfileImpressionEvent,
} from "../../utils/GTM";
import useCLIonLifeCycleContext from "../../utils/CLIonLifeCycleContext";
import {
  appEventEmitter,
  AppEventLinkToExternalRedemptionOrder,
} from "../../utils/SimpleEventEmitter";
import { isEmpty } from "../../utils/String";

import styles from "./styles.module.scss";
import { useSettingDisplayInfo } from "./hooks";
import { useAppConfig } from "../../repository/ConfigRepository";
import { useFetchBingoListCount } from "../../repository/BingoListRepository";
import BingoListContext from "../../contexts/BingoListContext";

import { useScrollToTopWhenClickTabBar } from "../../hook/scrollToTopWhenClickTabBar";
import { useModalState } from "../../hook/modal";
import { apolloClient } from "../../api/GraphQL";

import Config from "../../Config";

function checkIsLoggedIn(
  customer: Customer | null
): [true, Customer] | [false, null] {
  return customer != null ? [true, customer] : [false, null];
}

function viewEnter() {
  pageView({ page: "Account" });
}

const AccountPage: React.FC = () => {
  const { navigate } = useContext(OurNavContext);
  const { presentLoginModal } = useContext(LoginSignupModalContext);
  const { presentLocalizedAlert } = useContext(LocalizedAlertContext);
  const contentRef = useRef<HTMLIonContentElement>(null);
  const ionLifeCycleContext = useCLIonLifeCycleContext();
  ionLifeCycleContext.onIonViewDidEnter(viewEnter);
  useScrollToHideTabBar(contentRef, ionLifeCycleContext);
  const {
    show: showLoading,
    hide: hideLoading,
    withLoadingModalAsync,
  } = useContext(LoadingModalContext);
  const [
    theClubSSOConfirmationModalShown,
    showTheClubSSOConfirmationModal,
    hideTheClubSSOConfirmationModal,
  ] = useModalState();
  const [
    theClubSSOConfirmationStatusResult,
    setTheClubSSOConfirmationStatusResult,
  ] = useState<TheClubSSOCOnfirmationStatusResult | null>(null);
  const [ssoLoginResult, setSSOLoginResult] = useState<SSOResponse | undefined>(
    undefined
  );

  const { isOnline } = useContext(NetworkStatusContext);

  const appConfig = useAppConfig();

  const { startRequesting: getMyCustomer } = useGetMyCustomer();
  const refreshCustomer = useRefreshCustomer();
  const _customer = useCustomer();
  const isLoggedInResult = useMemo(() => checkIsLoggedIn(_customer), [
    _customer,
  ]);
  const isTheClubMember = useMemo(
    () => (_customer ? isCustomerLinkedToTheClub(_customer) : false),
    [_customer]
  );

  const hasRedemptionTab = useMemo<boolean>(() => {
    if (appConfig == null) {
      return false;
    }
    const { redemptionTabCategoryId } = appConfig;
    if (
      Config.ENABLE_REDEMPTION_TAB &&
      redemptionTabCategoryId != null &&
      redemptionTabCategoryId !== "0"
    ) {
      return true;
    }
    return false;
  }, [appConfig]);
  const hasExternalRedemptionOrder = useMemo<boolean>(() => {
    if (appConfig == null) {
      return false;
    }
    return !!appConfig.linkToRedemptionOrder;
  }, [appConfig]);

  const shouldShowBingoList = isTheClubMember && !!Config.ENABLE_BINGO_LIST;
  useLogBingolistProfileImpressionEvent(
    ionLifeCycleContext,
    shouldShowBingoList
  );

  const settings = useMemo(
    () =>
      getSettings(
        isLoggedInResult[0],
        shouldShowBingoList,
        hasRedemptionTab,
        hasExternalRedemptionOrder
      ),
    [
      isLoggedInResult,
      shouldShowBingoList,
      hasRedemptionTab,
      hasExternalRedemptionOrder,
    ]
  );

  const logout = useLogout();

  const customerClubPoints = useMemo<number>(() => {
    if (!_customer) return 0;
    if (!isCustomerLinkedToTheClub(_customer)) return 0;
    return getCustomerClubPoints(_customer);
  }, [_customer]);

  const onClickSetting = useCallback(
    async (setting: Setting, index: number) => {
      switch (setting) {
        case Setting.orders:
          actionEvent("Customer Account Info", "Click", "My Orders");
          navigate(getPathForMyOrders(RootTab.account));
          break;
        case Setting.bingoList:
          actionEvent("Customer Account Info", "Click", "Bingo List");
          navigate(getPathForBingoList(RootTab.account));
          bingolistProfileClickEvent();
          break;
        case Setting.subscriptions:
          actionEvent("Customer Account Info", "Click", "My Subscriptions");
          navigate(getPathForMySubscriptions(RootTab.account));
          break;
        case Setting.productReviews:
          actionEvent("Customer Account Info", "Click", "My Reviews");
          navigate(getPathForCustomerProductReview(RootTab.account));
          break;
        case Setting.redemption:
          actionEvent("Customer Account Info", "Click", "Redeem Rewards");
          if (customerClubPoints > 0) {
            navigate(
              `${getPathForRedemptionTab()}?redemption_club_point=0-${customerClubPoints}`,
              { forceRerender: true }
            );
          } else {
            navigate(getPathForRedemptionTab(), { forceRerender: true });
          }
          break;
        case Setting.externalRedemptionOrder:
          if (appConfig && appConfig.linkToRedemptionOrder) {
            appEventEmitter.publish(
              AppEventLinkToExternalRedemptionOrder(
                appConfig.linkToRedemptionOrder
              )
            );
          }
          break;
        case Setting.addressBook:
          actionEvent("Customer Account Info", "Click", "Delivery Info");
          navigate(getPathForMyDelivery(RootTab.account));
          break;
        case Setting.myCards:
          actionEvent("Customer Account Info", "Click", "My Cards");
          navigate(getPathForMyCards(RootTab.account));
          break;
        case Setting.displayLanguage:
          actionEvent("Customer Account Info", "Click", "Display Language");
          navigate(getPathForDisplayLanguagePage(RootTab.account));
          break;
        case Setting.logout:
          actionEvent("Customer Account Info", "Click", "Logout");
          showLoading();
          try {
            await logout();
            await apolloClient.stop();
            await apolloClient.resetStore();
          } finally {
            hideLoading();
          }
          navigate(getPathForHomePage());
          break;
        case Setting.notifications:
          actionEvent("Customer Account Info", "Click", "Notifications");
          navigate(getPathForNotificationSettings(RootTab.account));
          break;
        case Setting.footerCMSLinks: {
          if (appConfig == null) {
            return;
          }

          actionEvent(
            "Customer Account Info",
            "Click",
            appConfig.footerCMSLinks[index].text
          );

          navigate(
            getPathForFooterCMSLinkGroup(
              RootTab.account,
              appConfig.footerCMSLinks[index].text
            )
          );
          break;
        }
        case Setting.changePassword:
          actionEvent("Customer Account Info", "Click", "Change Password");
          navigate(getPathForChangePassword(RootTab.account));
          break;
        case Setting.performanceRecords:
          navigate(getPathForPerformanceRecords(RootTab.account));
          break;
        default:
          alert(`TODO: onClickSetting ${setting} ${index}`);
      }
    },
    [navigate, logout, appConfig, customerClubPoints, showLoading, hideLoading]
  );
  const onClickEdit = useCallback(async () => {
    if (
      _customer &&
      isCustomerLinkedToTheClub(_customer) &&
      Config.ENABLE_THE_CLUB_SSO_MVP1 &&
      Config.ENABLE_THE_CLUB_SSO_MVP1
        .THE_CLUB_EDIT_SHOULD_GO_TO_THE_CLUB_MEMBER_PROFILE
    ) {
      navigate(getPathForAccountInformation(RootTab.account));
    } else {
      navigate(getPathForEditProfile(RootTab.account));
    }
  }, [_customer, navigate]);
  const onClickLogin = useCallback(() => {
    presentLoginModal();
  }, [presentLoginModal]);
  const [
    socialSignupTermsModalIsOpen,
    setSocialSignupTermsModalIsOpen,
  ] = useState(false);
  const onSocialSignupTermsModalRequestDismiss = useCallback(() => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
  }, [setSocialSignupTermsModalIsOpen]);

  const getTheClubSSOConfirmationStatus = useGetTheClubSSOConfirmationStatus();
  const handleSSOConfirmation = useCallback(
    async (customer: Customer) => {
      const theClubSSOConfirmationStatus = await getTheClubSSOConfirmationStatus(
        customer,
        "The Club"
      );
      if (theClubSSOConfirmationStatus.type !== "NoActionRequired") {
        showTheClubSSOConfirmationModal();
        setTheClubSSOConfirmationStatusResult(theClubSSOConfirmationStatus);
      }
    },
    [getTheClubSSOConfirmationStatus, showTheClubSSOConfirmationModal]
  );

  const handleSocialSignupTermsModalSuccess = useCallback(async () => {
    setSocialSignupTermsModalIsOpen(false);
    setSSOLoginResult(undefined);
    const customer = await withLoadingModalAsync(getMyCustomer);
    if (customer) {
      handleSSOConfirmation(customer);
    }
  }, [withLoadingModalAsync, getMyCustomer, handleSSOConfirmation]);
  const loginWithTheClub = useLoginWithTheClub();
  const loginWithOAuth = useLoginWithOAuthRequest();
  const handleTheClubButtonLoginWithTheClub = useCallback(async () => {
    actionEvent("Customer Account Info", "Click", "Login The Club");
    showLoading();
    try {
      const result = await loginWithTheClub();
      setSSOLoginResult(result);
      const customer = await loginWithOAuth(result.token, result.provider);
      if (!customer) {
        throw new Error("cannot-get-customer");
      }
    } catch (e) {
      if (!(e instanceof Error) || isEmpty(e.message)) {
        presentLocalizedAlert({
          headerId: "login.login_failed",
          messageId: "login.login_with_oauth_failed_text",
          buttons: [{ textMessageID: "try_again" }],
        });
        return;
      }
      if (e.message === "cancelled") {
        return;
      }
      if (e.message === "Customer not exist.") {
        setSocialSignupTermsModalIsOpen(true);
        return;
      }
      presentLocalizedAlert({
        headerId: "login.login_failed",
        message: e.message,
        buttons: [{ textMessageID: "try_again" }],
      });
    } finally {
      hideLoading();
    }
  }, [
    showLoading,
    hideLoading,
    loginWithTheClub,
    loginWithOAuth,
    setSocialSignupTermsModalIsOpen,
    presentLocalizedAlert,
  ]);

  const handleLoginSuccess = useCallback(() => {}, []);

  const isScreenActive = useCheckIsScreenActive();

  useScrollToTopWhenClickTabBar(RootTab.account, isScreenActive, contentRef);

  const theClubSectionHeight = useMemo(() => {
    if (_customer) {
      return 0;
    }
    return 106;
  }, [_customer]);

  const theClubSectionStyle = useMemo(
    () => ({
      height: `${theClubSectionHeight}px`,
      bottom: `-${theClubSectionHeight / 2}px`,
    }),
    [theClubSectionHeight]
  );

  const rootContainerStyle = useMemo(
    () => ({
      paddingTop: `${theClubSectionHeight / 2 + 16}px`,
    }),
    [theClubSectionHeight]
  );

  const refresherStyle = useMemo(
    () => ({
      paddingTop: `${theClubSectionHeight / 2 + 16}px`,
      height: `${60 + theClubSectionHeight / 2 + 16}px`,
    }),
    [theClubSectionHeight]
  );

  const refresherPullMin = useMemo(() => 60 + theClubSectionHeight, [
    theClubSectionHeight,
  ]);

  const profileHeaderStyle = useMemo(
    () => ({
      paddingBottom: `${theClubSectionHeight / 2}px`,
    }),
    [theClubSectionHeight]
  );

  const [, , refreshBingoListCount] = useFetchBingoListCount();
  const { updateBingoListCount } = useContext(BingoListContext);

  const refreshBingoListAndUpdateBingoListCount = useCallback(
    () =>
      isTheClubMember
        ? refreshBingoListCount()
            .then(updateBingoListCount)
            .catch(() => {})
        : Promise.resolve(),
    [refreshBingoListCount, updateBingoListCount, isTheClubMember]
  );

  ionLifeCycleContext.onIonViewDidEnter(
    refreshBingoListAndUpdateBingoListCount
  );

  const handleRefresh = useCallback(
    async (e: CustomEvent<RefresherEventDetail>) => {
      await Promise.all([
        refreshBingoListAndUpdateBingoListCount(),
        refreshCustomer.refreshCustomer(),
      ]);
      e.detail.complete();
    },
    [refreshBingoListAndUpdateBingoListCount, refreshCustomer]
  );

  return (
    <>
      <BackAndroidExitApp pageIsActive={isScreenActive} />
      <IonHeader>
        <IonToolbar mode="ios" className={styles.ionToolbar}>
          <ProfileHeader
            customer={_customer}
            onClickEdit={onClickEdit}
            onClickLogin={onClickLogin}
            style={profileHeaderStyle}
          />
        </IonToolbar>
        {!isLoggedInResult[0] ? (
          <AccountLinkTheClubSection
            className={styles.theClubSection}
            onClickTheClubButton={handleTheClubButtonLoginWithTheClub}
            style={theClubSectionStyle}
          />
        ) : null}
      </IonHeader>
      <CLContent ref={contentRef}>
        <IonRefresher
          slot="fixed"
          onIonRefresh={handleRefresh}
          pullMin={refresherPullMin}
          style={refresherStyle}
        >
          <IonRefresherContent />
        </IonRefresher>
        <NoInternetConnectionView isOnline={isOnline} hasData={true}>
          <div className={styles.rootContainer} style={rootContainerStyle}>
            {settings.map((setting, index) => {
              switch (setting) {
                case Setting.divider:
                  return <div key={index} className={styles.divider} />;
                default:
                  return (
                    <SettingRows
                      key={index}
                      setting={setting}
                      onClickSetting={onClickSetting}
                    />
                  );
              }
            })}
            <VersionInfo />
            <TabBarSpacePlaceholder />
          </div>
        </NoInternetConnectionView>
      </CLContent>
      <SocialSignupTermsAndConditionsModal
        isModalOpen={socialSignupTermsModalIsOpen}
        onRequestDismiss={onSocialSignupTermsModalRequestDismiss}
        onSuccess={handleSocialSignupTermsModalSuccess}
        provider={ssoLoginResult ? ssoLoginResult.provider : undefined}
        token={ssoLoginResult ? ssoLoginResult.token : undefined}
      />
      <TheClubSSOConfirmationFlow
        theClubSSOConfirmationModalShown={theClubSSOConfirmationModalShown}
        showTheClubSSOConfirmationModal={showTheClubSSOConfirmationModal}
        hideTheClubSSOConfirmationModal={hideTheClubSSOConfirmationModal}
        theClubSSOConfirmationStatusResult={theClubSSOConfirmationStatusResult}
        setTheClubSSOConfirmationStatusResult={
          setTheClubSSOConfirmationStatusResult
        }
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
};

interface SettingRowsProps {
  setting: Setting;
  onClickSetting: (setting: Setting, index: number) => void;
}

const SettingRows: React.FC<SettingRowsProps> = props => {
  const { setting, onClickSetting } = props;
  const appConfig = useAppConfig();
  const { getSettingDisplayInfo } = useSettingDisplayInfo(appConfig);
  const displayInfo = getSettingDisplayInfo(setting);
  const onClick = useCallback(
    (index: number) => {
      onClickSetting(setting, index);
    },
    [onClickSetting, setting]
  );
  return (
    <>
      {displayInfo.map((info, index) => (
        <SettingRow
          key={index}
          settingDisplayInfo={info}
          index={index}
          onClickSetting={onClick}
        />
      ))}
    </>
  );
};

interface SettingRowProps {
  settingDisplayInfo: SettingDisplayInfo;
  index: number;
  onClickSetting: (index: number) => void;
}

const SettingRow: React.FC<SettingRowProps> = props => {
  const { settingDisplayInfo, index, onClickSetting } = props;
  const onClick = useCallback(() => {
    onClickSetting(index);
  }, [onClickSetting, index]);
  return (
    <div key={index} className={styles.settingRow} onClick={onClick}>
      <div
        style={{ backgroundImage: `url(${settingDisplayInfo.iconPath})` }}
        className={styles.rowIcon}
      />
      <p className={styles.rowTitleText}>{settingDisplayInfo.title}</p>
      {settingDisplayInfo.count ? (
        <div className={styles.rowCount}>
          {settingDisplayInfo.count > 9 ? "9+" : settingDisplayInfo.count}
        </div>
      ) : null}
      {settingDisplayInfo.showRightArrow && <div className={styles.rowArrow} />}
    </div>
  );
};

interface ProfileHeaderProps {
  className?: string;
  style?: CSSProperties;
  customer: Customer | null;
  onClickLogin: () => void;
  onClickEdit: () => void;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = props => {
  const { style, className, customer, onClickLogin, onClickEdit } = props;
  const profilePicUrl = useMemo(
    () => (customer ? getCustomerProfilePicUrl(customer) : null),
    [customer]
  );
  if (customer != null) {
    return (
      <div
        className={classnames(className, styles.profileWrap)}
        onClick={onClickEdit}
        style={style}
      >
        {profilePicUrl ? (
          <div
            className={styles.profileImage}
            style={{ backgroundImage: `url(${profilePicUrl})` }}
          />
        ) : (
          <div className={styles.profileImagePlaceholder} />
        )}
        <div className={styles.profileInfo}>
          <div className={styles.profileName}>
            {getCustomerFullName(customer)}
          </div>
        </div>
        <div className={styles.profileEditContainer}>
          <div className={styles.profileEditText}>
            <LocalizedText messageID="page.account.edit" />
          </div>
          <div className={styles.profileArrow} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames(className, styles.profileWrap)}
      onClick={onClickLogin}
      style={style}
    >
      <div className={classnames(styles.profileImagePlaceholder)} />
      <div className={styles.profileSignupLoginInfo}>
        <div className={styles.profileSignupLoginText}>
          <LocalizedText messageID="page.account.signup" />
          <LocalizedText messageID="page.account.login" />
        </div>
        <div className={styles.profileArrow} />
      </div>
    </div>
  );
};

const VersionInfo: React.FC = () => {
  const [versionInfo, setVersionInfo] = useState<DeviceInfo | null>(null);
  useEffect(() => {
    getInfo().then(info => setVersionInfo(info));
  }, []);
  if (versionInfo == null) {
    return null;
  }
  return (
    <div className={styles.versionInfo}>
      <span>{`${versionInfo.appVersion} (${versionInfo.buildNumber})`}</span>
    </div>
  );
};

export default AccountPage;

function useLogBingolistProfileImpressionEvent(
  ionLifeCycleContext: IonLifeCycleContextInterface,
  shouldShowBingoList: boolean
) {
  const didLog = useRef(false);

  const doLogIfNotLogged = useCallback(() => {
    if (!didLog.current) {
      bingolistProfileImpressionEvent();
      didLog.current = true;
    }
  }, []);

  const didEnter = useCallback(() => {
    if (shouldShowBingoList) {
      doLogIfNotLogged();
    }
  }, [shouldShowBingoList, doLogIfNotLogged]);
  ionLifeCycleContext.onIonViewDidEnter(didEnter);

  useEffect(() => {
    if (shouldShowBingoList) {
      doLogIfNotLogged();
    }
  }, [shouldShowBingoList, doLogIfNotLogged]);

  const didLeave = useCallback(() => {
    didLog.current = false;
  }, []);
  ionLifeCycleContext.onIonViewDidLeave(didLeave);
}
