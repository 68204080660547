import {
  loginWithEmail,
  signupWithEmail,
  getMyCustomer,
  updateMyCustomerInterestCategories,
  updateMyCustomerInfo,
  updateMyCustomerInfoAfterSSOSignup,
  activateCustomer,
  updateMyCustomerEmail,
  resendChangeEmailConfirmation,
  cancelChangeEmailRequest,
  verifyChangeEmail,
  changePassword,
  changeEmailOnLogin,
  parseGraphQLError,
  deleteDeviceToken,
  registerDeviceToken,
} from "../api/GraphQL";
import {
  isCustomerSsoConfirmed,
  updateCustomerSsoConfirmation,
} from "../api/Auth";
import { setAccessToken, TokenStore } from "../api/TokenStore";
import { useContext, useCallback } from "react";
import { useFetchResources_v2 } from "./Hooks";
import { RepositoryContext } from "./State";
import { Customer } from "../models/Customer";
import { ResourcesRequestState } from "../models/ResourcesRequestState";
import { getOS, getPlatform, dummyIsdn } from "../models/OPNSPushNotification";
import {
  OAuthProvider,
  restAPIClient,
  loginWithOAuth,
  signupWithOAuth,
  linkSocialAccount,
  unlinkSocialAccount,
  LinkSocialAccountResponse,
  resetPassword,
} from "../api/RESTful";
import { useIntl } from "../i18n/Localization";
import { useApolloClient } from "@apollo/react-hooks";
import { CartIDContext } from "../components/CartIDProvider";
import { PushNotificationContext } from "../components/PushNotificationProvider";
import { setUserID } from "../utils/GTM";
import * as Storage from "../storage";
import { useKeepUpdatingRef } from "../hook/utils";
import { throwIfNothing } from "../types/Maybe";

import Config from "../Config";

export const INVALID_MEMBERSHIP_RESPONSE = "Invalid membership response.";
export const CANNOT_GET_CUSTOMER_ERROR = new Error("cannot-get-customer");

export function useActivateCustomer() {
  const apolloClient = useApolloClient();
  const fetchMyCustomer = useGetMyCustomerRequest();
  const loginDeviceTokenOnSuccess = useLoginDeviceTokenOnSuccess();
  return useCallback(
    async (customerId: number, confirmationKey: string) => {
      const token = await activateCustomer(
        apolloClient,
        customerId,
        confirmationKey
      );
      return loginDeviceTokenOnSuccess(fetchMyCustomer, token);
    },
    [apolloClient, fetchMyCustomer, loginDeviceTokenOnSuccess]
  );
}

export function useLoginWithEmailRequest(): (
  email: string,
  password: string
) => Promise<Customer | null> {
  const apolloClient = useApolloClient();
  const fetchCustomerRequest = useGetMyCustomerRequest();
  const { cartID, createCartFromPreviousCart } = useContext(CartIDContext);
  const loginDeviceTokenOnSuccess = useLoginDeviceTokenOnSuccess();

  return useCallback(
    async (email: string, password: string) => {
      const newToken = await loginWithEmail(apolloClient, email, password);
      return loginDeviceTokenOnSuccess(async () => {
        if (cartID != null) {
          await createCartFromPreviousCart(cartID);
        }
        const customer = await fetchCustomerRequest();
        return customer;
      }, newToken);
    },
    [
      apolloClient,
      fetchCustomerRequest,
      cartID,
      createCartFromPreviousCart,
      loginDeviceTokenOnSuccess,
    ]
  );
}

export function useSignupWithEmailRequest(): (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  isSubscribeToNewsletter: boolean
) => Promise<number> {
  const apolloClient = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      isSubscribeToNewsletter: boolean
    ) => {
      return signupWithEmail(
        apolloClient,
        locale,
        firstName,
        lastName,
        email,
        password,
        isSubscribeToNewsletter
      );
    },
    [apolloClient, locale]
  );
}

export function useLogin() {
  return useCallback(async (loginProvider: () => Promise<Customer | null>) => {
    const customer = await loginProvider();
    return throwIfNothing(CANNOT_GET_CUSTOMER_ERROR, customer);
  }, []);
}

export function useLogout(): () => Promise<void> {
  const { dispatch } = useContext(RepositoryContext);
  const { removeCart } = useContext(CartIDContext);
  const logoutDeviceToken = useLogoutDeviceToken();
  return useCallback(async () => {
    await removeCart();
    await logoutDeviceToken();
    await Storage.clearCustomer();
    dispatch({
      type: "Logout",
    });
    setUserID(undefined);
  }, [dispatch, removeCart, logoutDeviceToken]);
}

export function useCustomer() {
  const { state } = useContext(RepositoryContext);
  return state.customer;
}

export function useIsLoggedIn(): boolean {
  const { state } = useContext(RepositoryContext);
  return state.customer != null || TokenStore.accessToken != null;
}

export function useGetMyCustomerRequest(): () => Promise<Customer | null> {
  const { dispatch } = useContext(RepositoryContext);
  const apolloClient = useApolloClient();
  const getMyCustomer_ = useHandleSessionExpired(getMyCustomer);
  const { locale } = useIntl();
  return useCallback(async () => {
    const customer = await getMyCustomer_(apolloClient, locale);
    if (customer) {
      dispatch({
        type: "UpdateCustomer",
        customer,
      });
      setUserID(customer.id);
      await Storage.setCustomer(customer);
    }
    return customer;
  }, [dispatch, apolloClient, getMyCustomer_, locale]);
}

export function useGetMyCustomer(): {
  requestState: ResourcesRequestState<Customer | null>;
  startRequesting: () => Promise<Customer | null>;
} {
  const { dispatch } = useContext(RepositoryContext);
  const getMyCustomerRequest = useGetMyCustomerRequest();
  const customer = useCustomer();

  const [requestState, { call: startRequesting }] = useFetchResources_v2<
    Customer | null,
    () => Promise<Customer | null>
  >({
    memoryCacheProvider: () => Promise.resolve(customer),
    localCacheProvider: async () => {
      if (!TokenStore.accessToken) {
        return null;
      }
      const customerInStorage = await Storage.getCustomer();
      if (customerInStorage) {
        dispatch({
          type: "UpdateCustomer",
          customer: customerInStorage,
        });
      }
      return customerInStorage;
    },
    remoteResourcesProvider: async () => {
      if (!TokenStore.accessToken) {
        return null;
      }
      return getMyCustomerRequest();
    },
  });

  return { requestState, startRequesting };
}

export function useLoginWithOAuthRequest(): (
  oauthAccessToken: string,
  provider: OAuthProvider
) => Promise<Customer | null> {
  const customerRequest = useGetMyCustomerRequest();
  const { locale } = useIntl();
  const { cartID, createCartFromPreviousCart } = useContext(CartIDContext);
  const loginDeviceTokenOnSuccess = useLoginDeviceTokenOnSuccess();
  return useCallback(
    async (oauthAccessToken: string, provider: OAuthProvider) => {
      const token = await loginWithOAuth(
        restAPIClient,
        locale,
        oauthAccessToken,
        provider
      );
      if (token == null) {
        return null;
      }
      return loginDeviceTokenOnSuccess(async () => {
        if (cartID != null) {
          await createCartFromPreviousCart(cartID);
        }
        const customer = await customerRequest();
        return customer;
      }, token);
    },
    [
      locale,
      customerRequest,
      cartID,
      createCartFromPreviousCart,
      loginDeviceTokenOnSuccess,
    ]
  );
}

export function useSignupWithOAuthRequest(): (
  oauthAccessToken: string,
  provider: OAuthProvider,
  isSubscribeToNewsletter: boolean
) => Promise<Customer | null> {
  const apolloClient = useApolloClient();
  const { locale } = useIntl();
  const loginDeviceTokenOnSuccess = useLoginDeviceTokenOnSuccess();
  return useCallback(
    async (
      oauthAccessToken: string,
      provider: OAuthProvider,
      isSubscribeToNewsletter: boolean
    ) => {
      const useId = await signupWithOAuth(
        restAPIClient,
        locale,
        oauthAccessToken,
        provider
      );
      if (useId == null) {
        return null;
      }
      const token = await loginWithOAuth(
        restAPIClient,
        locale,
        oauthAccessToken,
        provider
      );
      if (token == null) {
        return null;
      }
      return loginDeviceTokenOnSuccess(
        () =>
          updateMyCustomerInfoAfterSSOSignup(
            apolloClient,
            isSubscribeToNewsletter
          ),
        token
      );
    },
    [locale, apolloClient, loginDeviceTokenOnSuccess]
  );
}

export function useUpdateMyCustomerInterestCategoriesRequest(): (
  categoryIds: number[]
) => Promise<Customer> {
  const { dispatch } = useContext(RepositoryContext);
  const apolloClient = useApolloClient();
  return useCallback(
    async (categoryIds: number[]) => {
      return updateMyCustomerInterestCategories(apolloClient, categoryIds).then(
        customer => {
          dispatch({
            type: "UpdateCustomer",
            customer,
          });
          setUserID(customer.id);
          return customer;
        }
      );
    },
    [dispatch, apolloClient]
  );
}

export function useUpdateMyCustomerInfoRequest(): (
  firstName: string,
  lastName: string,
  isSubscribeToNewsletter: boolean,
  updatedProfilePic?: string
) => Promise<Customer> {
  const { dispatch } = useContext(RepositoryContext);
  const apolloClient = useApolloClient();
  return useCallback(
    async (
      firstName: string,
      lastName: string,
      isSubscribeToNewsletter: boolean,
      updatedProfilePic?: string
    ) => {
      return updateMyCustomerInfo(
        apolloClient,
        firstName,
        lastName,
        isSubscribeToNewsletter,
        updatedProfilePic
      ).then(customer => {
        dispatch({
          type: "UpdateCustomer",
          customer,
        });
        setUserID(customer.id);
        return customer;
      });
    },
    [dispatch, apolloClient]
  );
}

export function useLinkSocialAccountRequest(): (
  oauthAccessToken: string,
  provider: OAuthProvider
) => Promise<LinkSocialAccountResponse & { customer?: Customer }> {
  const getCustomer = useGetMyCustomerRequest();
  const { locale } = useIntl();
  const { cartID, createCartFromPreviousCart } = useContext(CartIDContext);
  return useCallback(
    async (oauthAccessToken: string, provider: OAuthProvider) => {
      const result = await linkSocialAccount(
        restAPIClient,
        locale,
        oauthAccessToken,
        provider
      );
      if (result.success === true) {
        if (cartID != null) {
          await createCartFromPreviousCart(cartID);
        }
        const customer = await getCustomer();
        return { ...result, customer: customer ? customer : undefined };
      }
      return result;
    },
    [locale, getCustomer, cartID, createCartFromPreviousCart]
  );
}

export function useUnlinkSocialAccountRequest(): (
  provider: OAuthProvider
) => Promise<boolean> {
  const getCustomer = useGetMyCustomerRequest();
  const { locale } = useIntl();
  const { cartID, createCartFromPreviousCart } = useContext(CartIDContext);
  return useCallback(
    async (provider: OAuthProvider) => {
      const result = await unlinkSocialAccount(restAPIClient, locale, provider);
      if (result === true) {
        if (cartID != null) {
          await createCartFromPreviousCart(cartID);
        }
        await getCustomer();
      }
      return result;
    },
    [locale, getCustomer, cartID, createCartFromPreviousCart]
  );
}

export function useResetPassword(): (email: string) => Promise<void> {
  const { locale } = useIntl();
  return useCallback(
    (email: string) => {
      return resetPassword(restAPIClient, email, locale);
    },
    [locale]
  );
}

export function useUpdateMyCustomerEmailRequest(): (
  email: string,
  password: string
) => Promise<Customer> {
  const apolloClient = useApolloClient();
  const getCustomer = useGetMyCustomerRequest();
  const { locale } = useIntl();
  return useCallback(
    async (email: string, password: string) => {
      await updateMyCustomerEmail(apolloClient, email, password, locale);
      const customer = await getCustomer();
      if (!customer) {
        throw new Error("no-customer");
      }
      return customer;
    },
    [apolloClient, getCustomer, locale]
  );
}

export function useResendChangeEmailConfirmation(): () => Promise<void> {
  const apolloClient = useApolloClient();
  const { locale } = useIntl();
  return useCallback(() => {
    return resendChangeEmailConfirmation(apolloClient, locale);
  }, [apolloClient, locale]);
}

export function useCancelChangeEmailRequest(): () => Promise<void> {
  const apolloClient = useApolloClient();
  const getCustomer = useGetMyCustomerRequest();
  const { locale } = useIntl();
  return useCallback(async () => {
    await cancelChangeEmailRequest(apolloClient, locale);
    await getCustomer();
  }, [apolloClient, getCustomer, locale]);
}

export function useVerifyChangeEmail(): (
  customerID: number,
  key: string
) => Promise<void> {
  const apolloClient = useApolloClient();
  const getCustomer = useGetMyCustomerRequest();
  const { locale } = useIntl();
  return useCallback(
    async (customerID: number, key: string) => {
      await verifyChangeEmail(apolloClient, customerID, key, locale);
      await getCustomer();
    },
    [apolloClient, getCustomer, locale]
  );
}

export function useChangePassword(): (
  currentPassword: string,
  newPassword: string
) => Promise<void> {
  const apolloClient = useApolloClient();
  return useCallback(
    async (currentPassword: string, newPassword: string) => {
      await changePassword(apolloClient, currentPassword, newPassword);
    },
    [apolloClient]
  );
}

export function useUpdateCustomerEmailOnLogin(): (
  email: string
) => Promise<{ success: boolean; reject_reason?: string }> {
  const apolloClient = useApolloClient();
  const getCustomer = useGetMyCustomerRequest();
  return useCallback(
    async (email: string) => {
      const result = await changeEmailOnLogin(apolloClient, email);
      if (result.success) {
        await getCustomer();
      }
      return result;
    },
    [apolloClient, getCustomer]
  );
}

export function useRefreshCustomer() {
  const { requestState, startRequesting } = useGetMyCustomer();

  const refreshCustomer = useCallback(async () => {
    await startRequesting().catch(() => {});
  }, [startRequesting]);

  return {
    refreshCustomer,
    requestState,
  };
}

type SessionExpiredMessage =
  | "The current customer isn't authorized."
  | "The current user cannot perform operations on wishlist";

function isSessionExpiredMessage(
  message: string
): message is SessionExpiredMessage {
  const m = message as SessionExpiredMessage;
  return (
    m === "The current customer isn't authorized." ||
    m === "The current user cannot perform operations on wishlist"
  );
}

export function useHandleSessionExpired<T, Args extends any[]>(
  request: (...args: Args) => Promise<T>
) {
  const { dispatch } = useContext(RepositoryContext);
  const logoutDeviceToken = useLogoutDeviceToken();

  const handleSessionExpired = useCallback(
    async (...args: Args) => {
      try {
        return await request(...args);
      } catch (e) {
        const graphQLErrorMessage = parseGraphQLError(e);
        if (
          graphQLErrorMessage &&
          isSessionExpiredMessage(graphQLErrorMessage)
        ) {
          await logoutDeviceToken();
          await Storage.clearCustomer();
          dispatch({ type: "SectionExpired" });
        }
        throw e;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, request, logoutDeviceToken]
  );

  return handleSessionExpired;
}

function useLoginDeviceToken(): (apiAccessToken: string) => Promise<any> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { deviceToken, enablePushNotification } = useContext(
    PushNotificationContext
  );
  const deviceTokenRef = useKeepUpdatingRef(deviceToken);
  const enablePushNotificationRef = useKeepUpdatingRef(enablePushNotification);
  return useCallback(
    async (apiAccessToken: string) => {
      const os = getOS();
      const platform = getPlatform();
      const _deviceToken = deviceTokenRef.current;
      const _enablePushNotification = enablePushNotificationRef.current;
      if (
        Config.USE_PUSH &&
        os &&
        platform &&
        _deviceToken &&
        _enablePushNotification
      ) {
        console.info(
          `[ClubLike-OPNS] Attempt to delete device token ${_deviceToken} for ${os}, ${platform} for guest`
        );
        try {
          await deleteDeviceToken(
            client,
            locale,
            os,
            platform,
            _deviceToken,
            dummyIsdn
          );
          console.info(
            `[ClubLike-OPNS] Deleted device token ${_deviceToken} for ${os}, ${platform} for guest`
          );
        } catch {
          console.warn(
            `[ClubLike-OPNS] Device token ${_deviceToken} for ${os}, ${platform} cannot be deleted for guest`
          );
        }
        await Storage.deletePNDeviceToken();
      }
      await setAccessToken(apiAccessToken);
      if (
        Config.USE_PUSH &&
        os &&
        platform &&
        _deviceToken &&
        _enablePushNotification
      ) {
        console.info(
          `[ClubLike-OPNS] Attempt to register device token ${_deviceToken} for ${os}, ${platform} from ${apiAccessToken.slice(
            0,
            4
          )}`
        );
        try {
          await registerDeviceToken(
            client,
            locale,
            os,
            platform,
            _deviceToken,
            dummyIsdn
          );
          await Storage.setPNDeviceToken(_deviceToken);
          console.info(
            `[ClubLike-OPNS] Registered device token ${_deviceToken} for ${os}, ${platform} from ${apiAccessToken.slice(
              0,
              4
            )}`
          );
        } catch {
          console.warn(
            `[ClubLike-OPNS] Device token ${_deviceToken} for ${os}, ${platform} for ${apiAccessToken.slice(
              0,
              4
            )} cannot be registered`
          );
        }
      }
    },
    [client, locale, deviceTokenRef, enablePushNotificationRef]
  );
}

function useLogoutDeviceToken(): () => Promise<void> {
  const client = useApolloClient();
  const { locale } = useIntl();
  const { deviceToken, enablePushNotification } = useContext(
    PushNotificationContext
  );
  const deviceTokenRef = useKeepUpdatingRef(deviceToken);
  const enablePushNotificationRef = useKeepUpdatingRef(enablePushNotification);
  return useCallback(async () => {
    const os = getOS();
    const platform = getPlatform();
    const _deviceToken = deviceTokenRef.current;
    const _enablePushNotification = enablePushNotificationRef.current;
    if (
      Config.USE_PUSH &&
      os &&
      platform &&
      _deviceToken &&
      _enablePushNotification
    ) {
      console.info(
        `[ClubLike-OPNS] Attempt to delete device token ${_deviceToken} for ${os}, ${platform} from ${
          TokenStore.accessToken ? TokenStore.accessToken.slice(0, 4) : null
        }`
      );
      try {
        await deleteDeviceToken(
          client,
          locale,
          os,
          platform,
          _deviceToken,
          dummyIsdn
        );
        console.info(
          `[ClubLike-OPNS] Deleted device token ${_deviceToken} for ${os}, ${platform} for ${
            TokenStore.accessToken ? TokenStore.accessToken.slice(0, 4) : null
          }`
        );
      } catch {
        console.warn(
          `[ClubLike-OPNS] Device token ${_deviceToken} for ${os}, ${platform} for ${
            TokenStore.accessToken ? TokenStore.accessToken.slice(0, 4) : null
          } cannot be deleted`
        );
      }
      await Storage.deletePNDeviceToken();
    }
    await setAccessToken(null);
    if (
      Config.USE_PUSH &&
      os &&
      platform &&
      _deviceToken &&
      _enablePushNotification
    ) {
      console.info(
        `[ClubLike-OPNS] Attempt to register device token ${_deviceToken} for ${os}, ${platform} for guest`
      );
      try {
        await registerDeviceToken(
          client,
          locale,
          os,
          platform,
          _deviceToken,
          dummyIsdn
        );
        await Storage.setPNDeviceToken(_deviceToken);
        console.info(
          `[ClubLike-OPNS] Registered device token ${_deviceToken} for ${os}, ${platform} for guest`
        );
      } catch {
        console.warn(
          `[ClubLike-OPNS] Device token ${_deviceToken} for ${os}, ${platform} for guest cannot be registered`
        );
      }
    }
  }, [client, locale, deviceTokenRef, enablePushNotificationRef]);
}

function useLoginDeviceTokenOnSuccess() {
  const loginDeviceToken = useLoginDeviceToken();
  const logoutDeviceToken = useLogoutDeviceToken();
  return useCallback(
    async <T,>(promise: () => Promise<T>, token: string) => {
      try {
        await loginDeviceToken(token);
        return await promise();
      } catch (e) {
        await logoutDeviceToken();
        throw e;
      }
    },
    [loginDeviceToken, logoutDeviceToken]
  );
}

export function useIsCustomerSsoConfirmed() {
  const apolloClient = useApolloClient();
  return useCallback(() => isCustomerSsoConfirmed(apolloClient), [
    apolloClient,
  ]);
}

export function useUpdateCustomerSsoConfirmation() {
  const apolloClient = useApolloClient();
  const { locale } = useIntl();
  return useCallback(
    (marketingMaterials: boolean) =>
      updateCustomerSsoConfirmation(apolloClient, locale, marketingMaterials),
    [apolloClient, locale]
  );
}
